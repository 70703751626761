<template>
    <div class="bg">
        <div class="canvas-container">
            <div class="canvas"
                :style="{
                width: canvasStyleData.width + 'px',
                height: canvasStyleData.height + 'px',
                transform: 'scale(' + 1 + ')',
                background: canvasStyleData.background
            }">
                <ComponentWrapper
                    v-for="(item, index) in componentData"
                    :key="index"
                    :config="item"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { getStyle } from '@/utils/style'
import { mapState } from 'vuex'
import ComponentWrapper from '@/components/Editor/ComponentWrapper'
import { listenGlobalKeyDown } from '@/utils/shortcutKey'
import generateID from '@/utils/generateID'
import axios from 'axios'

export default {
    components: { ComponentWrapper },
    computed: mapState([
        'componentData',
        'canvasStyleData',
    ]),
    created() {
        this.restore()
        // 全局监听按键事件
        listenGlobalKeyDown()

        // 增加audio监听        
        document.removeEventListener('touchstart', this.playAudio)
        document.addEventListener('touchstart', this.playAudio)
        document.addEventListener('click', this.playAudio)
    },
    beforeDestroy() {

    },
    methods: {
        getStyle,

        restore() {
            let this_ = this
            let host = window.location.host.replace('.loveyou520.top', '').replace('.localhostvue', '')
            axios.post(this_.hostUrl+'/api/template/templateByHost', {
                domain: host,
            }).then(res => {
                if (!res.data.data) {
                    this.$alert('您访问的页面不存在,将为您跳转到主页', '温馨提示', {
                        confirmButtonText: '确定',
                        callback: action => {
                            window.location.href = 'http://loveyou520.top'
                        },
                    })
                    return
                }

                if (res.data.data.canvasData) {
                    this_.$store.commit('setComponentData', this_.resetID(JSON.parse(res.data.data.canvasData)))
                }
                if (res.data.data.canvasStyle) {
                    this_.$store.commit('setCanvasStyle', JSON.parse(res.data.data.canvasStyle))
                }
            })
        },

        resetID(data) {
            data.forEach(item => {
                item.id = generateID()
            })

            return data
        },

        playAudio() {
            let audio = document.getElementsByTagName('audio')
            if (audio) {
                for (let index = 0; index < audio.length; index++) {
                    const element = audio[index]
                    element.play()
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.bg {
    background: rgb(0, 0, 0, .5);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    .canvas-container {
        overflow: auto;
        width: 100%;
        .canvas {
            background: #fff;
            margin: auto;
            overflow:hidden;
        }
    }
}
</style>